<script setup>

import { onMounted, ref, computed } from 'vue';
import { PopUpManager as PopUp } from '@/Ship';
import { useProfileActions } from '@profile';

const {
    uploadDocument,
    removeDocument,
    getProfileResource
} = useProfileActions();

const profile = getProfileResource();

let documents = ref([
    {
        url: null,
        key: 'id_front',
        name: 'ID Card (front)'
    },
    {
        url: null,
        key: 'id_back',
        name: 'ID Card (back)'
    },
    {
        url: null,
        key: 'card_front',
        name: 'Card (front)'
    },
    {
        url: null,
        key: 'card_back',
        name: 'Card (back)'
    },
    {
        url: null,
        key: 'dod',
        name: 'DOD'
    },
    {
        url: null,
        key: 'ub',
        name: 'UB'
    },
]);

const host = computed(() => {
    return process.env.VUE_APP_SERVER;
});

onMounted(() => {
    for (const key in profile.documents) {
        const index = documents.value.findIndex(doc => doc.key === key);
        if (index > -1) {
            documents.value[index].url = profile.documents[key]?.url;
        }
    }
});

const onFileChange = (e, target) => {
    let files = e.target.files || e.dataTransfer.files;
    return !files.length
        ? null
        : createImage(files[0], target);
};

const url2File = (url, filename, mimeType) => {
    return (fetch(url)
            .then(function (res) {return res.arrayBuffer();})
            .then(function (buf) {return new File([buf], `${filename}.jpeg`, { type: mimeType });})
    );
};

const createImage = (file, target) => {
    let img = new Image();
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = event => {
        img.src = event.target.result;

        img.onload = function () {

            let canvas = document.createElement('canvas');
            let ctx = canvas.getContext('2d');

            canvas.width = (img.width > img.height)
                ? 800
                : 500;
            canvas.height = img.height * (canvas.width / img.width);

            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.drawImage(img, 0, 0, img.width, img.height, 0, 0, canvas.width, canvas.height);

            url2File(canvas.toDataURL('image/jpeg'), target, 'image/jpeg').then((encodedFile) => {
                uploadDocument(target, encodedFile).then((response) => {
                    const index = documents.value.findIndex(doc => doc.key === target);
                    documents.value[index].url = response.url;
                });
            });
        };
    };
};

function view(index) {
    PopUp.open(require('@profile/components/PopUps/ViewDocument'))
        .setCaption(documents.value[index].name)
        .setPayload({payload: {
            url: documents.value[index].url
        }});
}

function remove(index) {
    removeDocument(documents.value[index].key).then(() => {
        documents.value[index].url = null;
    });
}
</script>

<template>
    <div class="documents">

        <g-caption weight="600" size="2">{{ $t('profile.documents', 'Documents') }}</g-caption>

        <g-flex class="doc-item" v-for="(item, i) in documents" v-bind:key="item.key" justify="between" align="center">

            <b>{{ item.name }}</b>
            <template v-if="!!item.url">
                <!--
                <img v-bind:src="`${host}${item.url}`" v-bind:alt="item.name"/>
                <span class="remove-btn" v-on:click.stop="removeDocument(i)">✕</span>
                -->
                <g-flex inline align="center" gap="5">
                    <g-button class="view-btn"
                              type="button"
                              v-on:click="view(i)">
                        {{ $t('base.view', 'View') }}
                    </g-button>
                    <g-button class="remove-btn" type="button" v-on:click.stop="remove(i)">
                        <g-symbol name="delete" width="20" height="20"/>
                    </g-button>
                </g-flex>
            </template>
            <label class="import-btn" v-else>
                {{ $t('base.import', 'Import') }}
                <g-symbol name="import" width="16" height="16"/>
                <input type="file"
                       v-on:change="onFileChange($event, item.key)"
                       accept="image/jpeg, image/png, image/webp"
                       hidden/>
            </label>
        </g-flex>
    </div>
</template>

<style lang="scss" scoped>
.documents {
    background-color: rgb($bar-bg, 0.5);
    border: 1px solid var(--separator-color, $separator-color);

    & > .doc-item {
        margin: 15px 0;
    }
}

.g-caption {
    color: var(--title-text-color, $title-text-color);
}

.import-btn {
    fill: white;
    color: white;
    padding: 0 26px;
    cursor: pointer;
    gap: 5px;
    display: inline-flex;
    align-items: center;
    height: var(--btn-heigh, $btn-height);
    background-color: var(--apply-btn, $apply-btn);
    border-radius: var(--bar-rounding, $bar-rounding);

    &:hover {
        background-color: var(--apply-btn-hover, $apply-btn-hover);
    }
}

.g-button {
    padding: 0 15px;

    &.view-btn {
        background-color: var(--main-text-color, $main-text-color);

        &:hover {
            background-color: var(--title-text-color, $title-text-color);
        }
    }

    &.remove-btn {
        background-color: transparent;
        border: 1px solid var(--main-text-color, $main-text-color);
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            fill: var(--title-text-color, $title-text-color);
            border-color: var(--title-text-color, $title-text-color);
        }
    }
}
</style>
