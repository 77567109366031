<template>
    <div class="trading">
        <g-tabs v-model="tab" v-bind:tab-list="{Positions: 'Positions', History: 'History'}"/>

        <component v-bind:is="tab"/>
    </div>
</template>

<script>

import { ref } from 'vue';
import { usePositionComponents } from '@position';

const {
    Positions,
    History,
} = usePositionComponents();

export default {
    components: {
        Positions,
        History,
    },

    setup() {
        const tab = ref('Positions');

        return {
            tab,
        };
    }
};

</script>

<style scoped lang="scss">
.g-tabs {
    background-color: var(--bar-bg, $bar-bg);
}

.trading {
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 2px solid var(--separator-color, $separator-color);
    background-color: rgba($bar-bg, 0.5);
}
</style>