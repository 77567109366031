<script setup>
import { computed } from 'vue';
import moment from 'moment';
import { useNewsfeedActions } from '@/Containers/Trading/Newsfeed';


const getNewsfeedResource = useNewsfeedActions('getNewsfeedResource');
const newsfeed = getNewsfeedResource();
newsfeed.load();

const feed = computed(() => newsfeed.state.feed || []);


</script>

<template>
    <div class="newsfeed" ref="element">

        <g-caption class="title" size="1" weight="800" align="center"><span>Trading News</span></g-caption>

        <ul v-if="feed.length > 0" class="newsfeed-list">
            <li class="newsfeed-item" v-for="item in feed" v-bind:key="item.title">
                <img v-bind:src="item.banner_image || require('@assets/img/pfx.svg')">
                <h3 v-bind:title="item.title">{{ item.title }}</h3>
                <div class="subttl">{{ item.source }} - {{ moment(item.time_published).format('MM-DD-YYYY') }}</div>
                <div class="summary">{{ item.summary }}</div>

                <a v-bind:href="item.url" target="_blank">{{ $t('base.read_more', 'Read more') }}</a>
            </li>
        </ul>
        <div v-else-if="newsfeed.state.Information">
            {{ newsfeed.state.Information }}
        </div>
    </div>
</template>

<style lang="scss" scoped>
.newsfeed {
    overflow: hidden;
    padding-left: 10px;
    padding-right: 10px;
}

.title > span {
    background: linear-gradient(239deg, #0d2a9d 0%, #a6d2f6 52.74%, #fff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.newsfeed-list {
    display: flex;
    column-gap: 16px;
    overflow: auto;
    padding-bottom: 16px;
}

.newsfeed-item {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 320px;
    padding: 20px;
    background-color: rgba($bar-bg, 0.3);
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 2px solid var(--separator-color, $separator-color);
    color: var(--main-text-color, $main-text-color);

    & > img {
        width: 100%;
        height: 150px;
        opacity: 0.7;
        border-radius: 9px;
        margin-bottom: 20px;
        object-fit: cover;
    }

    & > h3 {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 1.5rem;
        font-weight: 600;
        padding: 0;
        margin-bottom: 16px;
        color: var(--title-text-color, $title-text-color);
    }

    & > .subttl {
        margin-bottom: 8px;
    }

    & > .summary {
        margin-bottom: 16px;
        font-size: 1.1rem;
    }

    & > a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: var(--btn-rounding, $btn-rounding);
        height: var(--btn-height, $btn-height);
        color: white;
        background-color: transparent;
        border: 2px solid white;
        margin-top: auto;
        transition: background-color, 0.2s;

        &:hover {
            color: black;
            background-color: white;
        }
    }
}

@media (min-width: 985px) {
    .newsfeed {
        padding: 0;
    }
}
</style>
