<template>
    <div class="profile environment">
            <infobar/>
            <profile-form/>
            <documents class="disabled"/>
    </div>
</template>

<script>

import { STATE } from '@/Ship';
import { useProfileActions, useProfileComponents } from '@profile';

const getProfileResource = useProfileActions('getProfileResource');

const {
    ProfileForm,
    Infobar,
    Documents,
} = useProfileComponents();

export default {
    name: 'Environment',
    components: {
        ProfileForm,
        Infobar,
        Documents,
    },
    setup: function () {
        const profile = getProfileResource();

        let timeout = null;
        const logChanges = () => {

            if (profile.isValidationPassed()) {
                STATE.IS_PROCESSING = true;
                STATE.AUTO_SAVE = false;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    STATE.IS_PROCESSING = false;
                    STATE.AUTO_SAVE = true;
                    profile.save();
                    profile.clearEntity();
                }, 5000);
            }
        };

        return {
            profile,
            logChanges,
        };
    }
};
</script>

<style lang="scss" scoped>
.environment {
    padding: 0 10px;
    display: grid;
    margin: 0 auto;
    max-width: 1080px;//var(--env-width, $env-width);
}

.infobar {
    grid-area: bar;
}

.profile-form {
    grid-area: frm;
}

.documents {
    grid-area: doc;
}

@media (min-width: 825px) {
    .environment {
        grid-row-gap: 6px;
        grid-template-areas:
            'bar bar'
            'frm doc';
        grid-template-columns: 420px auto;
    }

    .profile-form {
        padding: 20px 30px 30px 50px;
        border-radius: var(--bar-rounding, $bar-rounding) 0 0 var(--bar-rounding, $bar-rounding);
    }

    .documents {
        padding: 20px 50px 30px 30px;
        border-radius: 0 var(--bar-rounding, $bar-rounding) var(--bar-rounding, $bar-rounding) 0;
        border-left: none;
    }
}

@media (max-width: 824px) {
    .environment {
        padding: 10px;
        grid-gap: 10px;
        grid-template-areas:
            'bar'
            'frm'
            'doc'
            'nws';
        grid-template-columns: 1fr;
    }

    .profile-form {
        padding: 20px;
        border-radius: var(--bar-rounding, $bar-rounding);
    }

    .documents {
        padding: 20px 30px;
        border-radius: var(--bar-rounding, $bar-rounding);
    }
}

@media (min-width: 724px) {
    .environment {
        grid-template-rows: var(--bar-height, $bar-height) auto;
    }
}

</style>
