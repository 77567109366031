<script setup>

import { computed, ref } from 'vue';
import {
    useAuthActions,
    useAuthComponents,
} from '@auth';
import { useRouter } from 'vue-router';
import { i18n, PopUpManager as PopUp } from '@/Ship';

const codeLength = 5;

const {
    PasswordInput,
    // VerificationCode,
} = useAuthComponents();

const step = ref(1);
const code = ref('');
const email = ref('');
const password = ref('');

const isComplex = ref(false);

const router = useRouter();

const timer = ref(179);
let interval;

const {
    resetPassword,
    forgotPassword,
} = useAuthActions();

const codeFilled = computed(() => code.value.length === codeLength);

function keydownHandler(event) {
    if (
        /\d/.test(event.key) ||
        event.key === 'ArrowRight' ||
        event.key === 'ArrowLeft' ||
        event.key === 'Backspace' ||
        (event.ctrlKey || event.metaKey) && /\w/.test(event.key)
    ) {
        return;
    }

    event.preventDefault();
}

function pasteHandler(event) {
    const text = event.clipboardData.getData('text').trim();
    if (!/^[0-9]*$/.test(text) || text.length !== codeLength) {
        event.preventDefault();
    }
}

function forgot() {
    forgotPassword(email.value).then(() => {
        step.value = 2;

        timer.value = 179;
        clearInterval(interval)
        interval  = setInterval(() => {
            if (timer.value > 0) {
                timer.value--;
            } else {
                clearInterval(interval);
            }
        }, 1000);
    }).catch(() => {
        PopUp.open(require('@auth/components/signUpInfoPopUp'))
            .setCaption(i18n.global.t('base.info'))
            .setPayload({
                payload: {
                    mainText: i18n.global.t('base.something_wrong'),
                    additionText: i18n.global.t('auth.no_user_found_by_email')
                }
            });
    });
}

function reset() {
    resetPassword({
        code: code.value,
        password: password.value,
        domain: location.hostname,
    }).then(() => {
        router.push({ name: 'Trading', params: { locale: i18n.global.locale.value } });
    }).catch(() => {
        PopUp.open(require('@auth/components/signUpInfoPopUp'))
            .setCaption(i18n.global.t('base.info'))
            .setPayload({
                payload:
                    {
                        mainText: i18n.global.t('base.something_wrong'),
                        additionText: i18n.global.t('auth.wrong_secret_code')
                    }
            });
    });
}

const convertSeconds = (left) => {
    const minutes = Math.floor((left % 3600) / 60)
    const seconds = left - (minutes * 60);

    return `${minutes}:${seconds}`;
}

</script>

<template>
    <div class="reset-password">
        <ul class="list-step">
            <li class="active"></li>
            <li v-bind:class="{active: step === 2 }"></li>
        </ul>

        <form class="step-container" v-if="step===1" v-on:submit.prevent="forgot">
            <g-caption size="1" weight="800" align="center"><span>{{ $t('auth.reset_password', 'Reset password') }}</span></g-caption>


            <div class="subtitle">{{ $t('auth.reset_sub_title_email') }}</div>

            <g-input v-model="email" type="email" v-bind:placeholder="$t('auth.email', 'Email')"/>

            <g-flex gap="10">
                <g-button class="cancel-btn" v-on:click.prevent.stop="$router.push({ name: 'SignIn', params: { locale: $i18n.locale } })">
                    {{ $t('base.cancel') }}
                </g-button>
                <g-button class="send-btn" v-bind:disabled="!/^([a-z0-9_-]+\.)*[a-z0-9_-]+@[a-z0-9_-]+(\.[a-z0-9_-]+)*\.[a-z]{2,6}$/.test(email)">
                    <g-symbol name="send" width="24" height="24"/>
                    {{ $t('base.send') }}
                </g-button>
            </g-flex>
        </form>

        <form v-else-if="step===2" class="step-container" v-on:submit.prevent="reset">
            <g-caption size="1" weight="800" align="center"><span>{{ $t('auth.reset_password', 'Reset password') }}</span></g-caption>

            <div>
                <g-caption size="4" align="center" weight="700">{{ $t('auth.enter_code') }}</g-caption>
                <div class="subtitle">
                    {{ $t('auth.reset_sub_title_code') }}
                    <div>{{ email }}</div>
                </div>
            </div>

            <!-- <verification-code v-model="code" v-model:filled="codeFilled"/> -->
            <g-input
                v-model="code"
                v-on:keydown="keydownHandler"
                v-on:paste="pasteHandler"
                v-bind:maxlength="codeLength"
            />

            <div class="subtitle">{{ $t('auth.reset_sub_title_password') }}</div>
            <password-input v-model="password" v-model:complex="isComplex" v-bind:label="$t('profile.new_password')"/>

            <g-flex gap="10">
                <g-button v-on:click.prevent.stop="$router.push({ name: 'SignIn', params: { locale: $i18n.locale } })" class="cancel-btn">
                    {{ $t('base.cancel', 'Cancel') }}
                </g-button>
                <g-button class="change-btn" v-bind:disabled="!codeFilled || !isComplex || !timer">
                    {{ $t('base.apply', 'Apply') }}
                </g-button>
            </g-flex>

            <div v-if="timer" class="subtitle">
                {{ $t('auth.timer') }} <span>{{ convertSeconds(timer) }}</span>
            </div>
            <div v-else>
                <g-button class="resend" type="button" v-on:click="forgot">Resend code</g-button>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
.g-caption > span {
    background: linear-gradient(239deg, #e2b63c 0%, #edf6a6 52.74%, #fff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.step-container {
    width: 100%;
    min-width: 320px;
    gap: 20px;
    display: flex;
    flex-direction: column;
}

.g-input,
.g-button,
.password-input,
.verification-code {
    flex-basis: 100%;
}

.cancel-btn {
    background-color: var(--disabled-color, $disabled-color);
    color: var(--main-text-color, $main-text-color);

    &:hover {
        color: var(--title-text-color, $title-text-color)
        //background-color: var(--btn-bg-hover, $btn-bg-hover);
    }
}

.send-btn,
.change-btn {
    background-color: var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }

    &:active {
        background-color: var(--login-btn-active, $login-btn-active);
    }
}

.g-caption {
    text-align: center;
    color: var(--home-page-color, $cover-color);
}

.subtitle {
    text-align: center;
    color: var(--title-text-color, $title-text-color);
}

.send-btn {
    fill: var(--main-text-color, $main-text-color);
}

.list-step {
    display: flex;
    gap: 10px;
    justify-content: center;
    list-style: none;
    margin-bottom: 40px;

    li {
        width: 40px;
        height: 6px;
        border-radius: 3px;
        background: var(--disable-color, $disabled-color);

        &.active {
            background: var(--primary, $primary);
        }
    }
}

.resend {
    padding: 0 20px;
    background-color: var(--primary, $primary);

    &:hover {
        background-color: var(--primary-hover, $primary-hover);
    }
}
</style>
