<script setup>

import { STATE } from '@/Ship';
import { ref, computed } from 'vue';
import { useQuoteActions, useQuoteComponents } from '@quotes';

const {
    getCryptoResource,
    getStockResource,
    getForexResource,
    getCommodityResource,
} = useQuoteActions();

const previousPrice= ref(0);
const increased = ref('increase');

const resources = {
    crypto: getCryptoResource,
    stocks: getStockResource,
    forex: getForexResource,
    commodities: getCommodityResource,
};

const ticker = computed(() => {
    const resource = resources[STATE.MARKET]();
    return resource.state[resource.index] || resource.model;
});

const priceScale = ref(false);
const marketPrice = computed(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    priceScale.value = (marketPrice.value || 0) > previousPrice.value
                ? 'scale-increase'
                : 'scale-decrease';

    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    previousPrice.value = marketPrice.value;

    return ticker.value.price;
});

const change24h = computed(() => {
    // eslint-disable-next-line vue/no-side-effects-in-computed-properties
    increased.value = marketPrice.value > ticker.value.open
        ? 'increase'
        : 'decrease';

    const precision = Number(marketPrice.value).toString().split('.')[1]?.length || 2;

    return (marketPrice.value - ticker.value.open).toFixed(precision < 2 ? 2 : precision);
});

const change24hPercent = computed(() => {
    return (marketPrice.value - ticker.value.open) * 100 / ticker.value.open;
});
</script>

<template>
    <div class="active-ticker">
        <g-flex align="center" justify="between" direction="column" wrap>
            <g-symbol-currency v-bind:name="ticker.base.toLowerCase()"/>

            <g-flex class="first-row" justify="between" align="center">
                <div class="name">{{ ticker.asset.name }}</div>
                <div class="price" v-bind:class="priceScale">{{ marketPrice.toString() }}</div>
            </g-flex>

            <g-flex class="second-row" justify="between" align="center">
                <div class="symbol">
                    <span class="base-currency">{{ ticker.base }}</span><span class="counter-currency">:{{ ticker.counter }}</span>
                </div>

                <g-flex inline gap="10" class="change">
                    <span v-bind:class="increased">{{ change24h }}</span>
                    <span class="percent" v-bind:class="increased">
                        {{ change24hPercent.toFixed(2) }}%
                    </span>
                </g-flex>
            </g-flex>


            <!--
            <g-flex class="specifics" justify="between" align="top" gap="12">
                <div>
                    <div class="title">24h Vol.</div>
                    {{ ticker.volume_24 }}
                </div>
                <div>
                    <div class="title">24h High</div>
                    {{ ticker.high }}
                </div>
                <div>
                    <div class="title">24h Low</div>
                    {{ ticker.low }}
                </div>
                <div>
                    <div class="title">24h Change</div>
                    {{ Math.abs(change24h) >= 0.000001 ? change24h.toFixed(2) : '> 0.000001' }}
                </div>
            </g-flex>
            -->
        </g-flex>
        </div>
</template>

<style scoped lang="scss">
.active-ticker {
    z-index: 3;
    width: 100%;
    padding: 8px 20px;
    position: relative;
    line-height: 1.1;
    border-radius: var(--bar-rounding, $bar-rounding);
    border: 2px solid var(--separator-color, $separator-color);
    background-color: rgba($bar-bg, 0.5);
    container-type: inline-size;

    & > .g-flex {
        height: 100%;
        column-gap: 10px;
    }
}

.g-symbol-currency {
    height: 100%;
    aspect-ratio: 1/1;
}

.first-row {
    height: 60%;
    padding-right: 10px;
    font-weight: 600;
    color: var(--title-text-color, $title-text-color);

    & > .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 200px;
    }
}

.second-row {
    height: 40%;
    padding-right: 10px;
}

/*
.price {
    &.decrease > .percent::before {
        transform: rotate(45deg);
    }

    &.increase > .percent::before {
        transform: rotate(-45deg);
    }
}

.percent::before {
    content: '⮕';
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    display: inline-block;
    transition: transform 0.2s;
    font-weight: bold;
    margin-right: 5px;
}
*/

.increase {
    color: var(--increase, $increase);
}

.decrease {
    color: var(--decrease, $decrease);
}

.specifics {
    margin-top: 5px;
    font-size: 14px;
    text-align: center;
    white-space: nowrap;

    & > :first-child {
        text-align: left;
    }

    & > :last-child {
        text-align: right;
    }

    .title {
        opacity: 0.7;
        font-size: 11px;
    }
}

@container (max-width: 364px) {
    .g-symbol-currency {
        width: 56px;
    }

    .first-row {
        font-size: 1.5rem;
        width: calc(100% - 56px);
    }

    .second-row {
        font-size: 1rem;
        width: calc(100% - 56px);
    }
}

@container (min-width: 365px) {
    .g-symbol-currency {
        width: 80px;
    }

    .first-row {
        font-size: 2rem;
        width: calc(100% - 80px);
    }

    .second-row {
        font-size: 1.2rem;
        width: calc(100% - 80px);
    }
}

@container (max-width: 560px) {
    .specifics {
        display: none !important;
    }
}
</style>