<script setup>

import { ref, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthActions } from '@auth';
import { i18n, PopUpManager as PopUp, STATE, verifyField } from '@/Ship';
import { useProfileActions } from '@profile';

const signIn = useAuthActions('signIn');
const getProfileResource = useProfileActions('getProfileResource');

const router = useRouter();
const profile = getProfileResource();

const rememberMe = ref(false);
const showPassword = ref(false);
const payload = reactive({
    email: '',
    password: '',
});

if (localStorage.getItem('rememberMe')) {
    payload.email = localStorage.getItem('email');
    payload.password = localStorage.getItem('password');
    rememberMe.value = true;
}

const errors = reactive({
    email: null,
    password: null,
});

const email = computed({
    get: () => payload.email,
    set: (value) => {
        payload.email = value;
        errors.email = verifyField(email.value, ['require', 'email']);
        return true;
    }
});

const password = computed({
    get: () => payload.password,
    set: (value) => {
        payload.password = value;
        return true;
    }
});

const login = () => {
    if (!errors.email) {
        signIn(payload.email, payload.password).then(() => {
            if (rememberMe.value) {
                localStorage.setItem('email', payload.email);
                localStorage.setItem('password', payload.password);
                localStorage.setItem('rememberMe', true);
            } else {
                localStorage.removeItem('email');
                localStorage.removeItem('password');
                localStorage.removeItem('rememberMe');
            }

            return profile.load().then(() => {
                STATE.IS_LOGIN = true;
                router.push({ name: 'Trading', params: router.currentRoute.value.params });
            });
        }).catch(() => {
            PopUp.open(require('@auth/components/signUpInfoPopUp'))
                .setCaption(i18n.global.t('base.info'))
                .setPayload({payload: {
                    mainText: i18n.global.t('base.something_wrong'),
                    additionText: i18n.global.t('auth.wrong_login_or_password')
                }});
        });
    }
};
</script>

<template>
    <form class="sign-in" v-on:submit.prevent="login">
        <g-caption size="1" weight="800" align="center"><span>{{ $t('auth.sign_in') }}</span></g-caption>
        <g-field v-model="email"
                 type="email"
                 v-bind:label="$t('auth.email')"
                 v-bind:error="errors.email"/>
        <g-field v-model="password"
                 v-bind:type="showPassword ? 'text' : 'password'"
                 v-bind:label="$t('auth.password')"
                 v-bind:error="errors.password">
            <button type="button" class="button-input"
                    v-on:click="showPassword = !showPassword">
                <g-symbol :name="showPassword ? 'eye-open' : 'eye-close'" width="24" height="24"/>
            </button>
        </g-field>
        <g-checkbox v-model:checked="rememberMe">{{ $t('auth.remember', 'Remember Me') }}</g-checkbox>
        <router-link v-bind:to="{ name: 'ResetPassword', params: { locale: $i18n.locale } }" tag="a" class="link">
            {{ $t('auth.forgot_password') }}
        </router-link>

        <g-button>
            {{ $t('auth.sign_in') }}
        </g-button>

        <div class="center">
            {{ $t('auth.have_account') }}
            <router-link :to="{ name: 'SignUp', params: { locale: $i18n.locale } }" tag="a" class="link">
                {{ $t('auth.sign_up') }}
            </router-link>
        </div>
    </form>
</template>

<style lang="scss" scoped>
.sign-in {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.link {
    font-size: 106%;
    font-weight: 600;
    margin-left: 5px;
    color: var(--title-text-color, $title-text-color);
}

.center {
    flex-basis: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.g-caption > span {
    background: linear-gradient(239deg, #098a65 0%, #a6f6b0 52.74%, #fff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.g-button {
    margin: 25px 0;
    flex-basis: 100%;
    background-color: var(--login-btn, $login-btn);

    &:hover {
        background-color: var(--login-btn-hover, $login-btn-hover);
    }

    &:active {
        background-color: var(--login-btn-active, $login-btn-active);
    }
}

.button-input {
    display: flex;
    background-color: transparent;
}

.g-field {
    flex-basis: 100%;
    margin-bottom: 14px;
}
</style>