<script setup>

import { defineProps, computed } from 'vue';

const props = defineProps({
    payload: {
        type: Object
    },
});

const host = computed(() => {
    return process.env.VUE_APP_SERVER;
});
</script>

<template>
    <div class="view-document">
        <img v-bind:src="`${host}${payload.url}`"/>
    </div>
</template>

<style scoped lang="scss">
.view-document {
    width: 800px;
    margin: calc(var(--popup-padding, $popup-padding) * -1);
    //margin: -40px;
}

img {
    max-height: 80vh;
}
</style>