<template>
    <div class="g-popup" v-on:click="PopUp.close()">
        <div class="popup" v-on:click.stop>
            <g-flex class="popup-header" justify="between" align="center">
                <g-caption size="3" weight="600" v-html="PopUp.caption"></g-caption>
                <g-symbol name="close" class="popup-close" v-on:click="PopUp.close()" height="24" width="24"/>
            </g-flex>
            <div class="popup-body" >
                <slot/>
            </div>
        </div>
    </div>
</template>

<script>

import { PopUpManager as PopUp } from '@/Ship';

export default {
    name: 'GPopup',
    components: {},

    setup() {
        return { PopUp };
    }
};
</script>

<style lang="scss" scoped>
.g-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    display: flex;
    overflow: auto;
    background-color: var(--popup-overlay, $popup-overlay);
    backdrop-filter: blur(6px);
}

.popup {
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    max-width: calc(100% - 10px);
    background-color: rgba($popup-bg, 0.6);
    border: 1px solid var(--separator-color, $separator-color);
    border-radius: var(--popup-rounding, $popup-rounding);
    box-shadow: 0 4px 40px -5px #00000077;
    backdrop-filter: blur(10px);
}

.popup-header {
    padding: 0 25px;
    height: var(--bar-height, $bar-height);
    background-color: var(--bar-bg, $bar-bg);
    box-shadow: 0 5px 25px -10px #00000055;
    color: var(--title-text-color, $title-text-color);
}

.popup-body {
    padding: var(--popup-padding, $popup-padding);
}

.popup-close {
    cursor: pointer;
    fill: var(--main-text-color, $main-text-color);

    &:hover {
        fill: var(--title-text-color, $title-text-color);
    }
}
</style>
