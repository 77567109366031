<script setup>

import { defineEmits, defineProps, watch } from 'vue';

const props = defineProps({
    tabList: {
        type: Object,
    },
    disables: {
        type: Array,
        default: [],
    },
    modelValue: {
        type: String,
        default: ''
    }
});
const emit = defineEmits(['update:modelValue', 'change']);
watch(() => props.modelValue, () => {
    emit('change', true);
});

</script>

<template>
    <div class="g-tabs">
        <button v-for="(value, key) in tabList"
             v-bind:key="key"
             class="tab"
             v-bind:class="[{'active': key === modelValue }, {disabled: disables.includes(key)}]"
             v-on:click="emit('update:modelValue', key)">
            {{ value }}
            <g-symbol name="lock" v-if="disables.includes(key)" width="16" height="16"/>
        </button>
    </div>
</template>

<style lang="scss" scoped>
.g-tabs {
    display: flex;
    gap: 4px;
    align-items: center;
    padding: 0 10px;
    font-family: $font-title;
    height: var(--bar-heigh, $bar-height);
    background-color: var(--bar-bg, $bar-bg);

    .tab {
        padding: 0 20px;
        height: var(--tab-height, $tab-height);
        line-height: var(--tab-height, $tab-height);
        text-align: center;
        font-weight: 500;
        font-size: 1.3rem;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: capitalize;
        border-radius: var(--tab-rounding, $tab-rounding);

        &:not(.active) {
            background-color: var(--tab-bg, $tab-bg);
            color: var(--tab-text-color, $tab-text-color);
        }

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }

        &.active {
            background-color: var(--tab-active-bg, $tab-active-bg);
            color: var(--tab-active-text-color, $tab-active-text-color);
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}

.g-symbol {
    fill: var(--disabled-color, $disabled-color);
}
</style>