<template>
    <div class="g-hamburger"
           v-bind:class="{active: modelValue}"
           v-on:click="$emit('update:modelValue', !modelValue)">
        <span></span>
        <span></span>
    </div>
</template>

<script>
export default {
    name: "GHamburger",

    props: {
        modelValue: {
            type: Boolean,
            default: false
        },
    },
    emits: ['update:modelValue'],
}
</script>

<style lang="scss" scoped>
.g-hamburger {
    width: 30px;
    height: 22px;
    margin: 6px;
    cursor: pointer;
    position: relative;
    border: solid var(--main-text-color, $main-text-color);
    border-width: 2px 0;
    transition: border-color 0.4s;

    & > span {
        top: 8px;
        width: 100%;
        height: 2px;
        position: absolute;
        background-color: var(--main-text-color, $main-text-color);
        transition: transform 0.5s;
    }

    &.active {
        border-color: transparent;

        & > span:nth-child(1) {
            transform: rotate(45deg);
        }

        & > span:nth-child(2) {
            transform: rotate(-45deg);
            background-color: var(--title-text-color, $title-text-color);
        }
    }
}
</style>