<script setup>

import {defineProps, defineEmits, watch, computed, reactive} from 'vue';
import {i18n, STATE} from '@/Ship';
import { useQuoteActions } from '@quotes';

const props = defineProps(['modelValue']);
defineEmits(['update:modelValue']);

const {
    getMarketResource,
    getCryptoResource,
    getStockResource,
    getForexResource,
    getCommodityResource,
} = useQuoteActions();

const markets = getMarketResource();

const resources = {
    crypto: getCryptoResource,
    stocks: getStockResource,
    forex: getForexResource,
    commodities: getCommodityResource,
};

const resource = computed(() => {
    return resources[STATE.MARKET]();
});

const timeOffset = new Date().getTimezoneOffset() * 60000;


const symbol = computed(() => {
    return resource.value.model.base.toLowerCase();
});

watch(() => resource.value.isLoaded, () => {
    if (resource.value.isLoaded) {
        markets.state.forEach(market => {
            if (market.status === 'closed' && !!market.openingTime) {
                market.openingTime *= 1000;
                market.countdown = market.openingTime - Date.now();
                let interval = setInterval(() => {
                    market.countdown = market.countdown - 1000;
                    market.openingTimer = new Date(market.countdown + timeOffset).toLocaleTimeString();
                    if (market.countdown < 0) {
                        delete market.openingTime;
                        market.status = 'opened';
                        clearInterval(interval);
                    }
                }, 1000);
            }
        });
    }
});

</script>

<template>
    <g-flex class="markets" align="center" justify="start" gap="2">
        <button v-for="market in markets.state"
                v-bind:key="market.name"
                type="button"
                class="tab"
                v-bind:class="[{active: modelValue === market.name}, market.status]"
                v-on:click="$emit('update:modelValue', market.name)">
            {{ market.name }}
        </button>
    </g-flex>
    <!--
    <div class="markets">
        <g-fade-container class="profile-menu"
                          icon="chart-bar"
                          v-bind:label="`${modelValue} ‧ market`">
            <template v-slot:icon>
                <g-symbol-currency class="icon" v-bind:name="symbol" width="28" height="28"/>
            </template>

            <div class="market-menu">
                <label v-for="market in markets.state"
                       v-bind:key="market.name"
                       v-bind:class="[{active: modelValue === market.name}, market.status]"
                       v-on:click="$emit('update:modelValue', market.name)">
                    <g-symbol v-if="market.status === 'closed'" name="lock" width="18" height="18"/>
                    <span class="market-name">{{ market.name }}</span>

                    <template v-if="market.status === 'closed'">
                        <span v-if="!!market.openingTime" class="timer" v-bind:data-countdown="market.openingTimer">Opens In:</span>
                        <span v-else-if="!!market.reason" class="reason">{{ market.reason }}</span>
                    </template>
                </label>
            </div>
        </g-fade-container>
    </div>
    -->
</template>

<style lang="scss" scoped>
.markets {
    top: 0;
    padding: 0 10px;
    position: sticky;
    height: var(--bar-height, $bar-height);
    background-color: var(--bar-bg, $bar-bg);
    container-type: inline-size;
}

.tab {
    height: var(--tab-height, $tab-height);
    line-height: var(--tab-height, $tab-height);
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;
    border-radius: var(--tab-rounding, $tab-rounding);

    &:not(.active) {
        background-color: var(--tab-bg, $tab-bg);
        color: var(--tab-text-color, $tab-text-color);
    }

    &:hover {
        color: var(--title-text-color, $title-text-color);
    }

    &.active {
        background-color: var(--tab-active-bg, $tab-active-bg);
        color: var(--tab-active-text-color, $tab-active-text-color);
    }

    &.disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

@container (min-width: 445px) {
    .tab {
        padding: 0 16px;
        font-size: 1.3rem;
    }
}

@container (max-width: 444px) {
    .tab {
        flex-grow: 1;
        font-size: 1.1rem;
    }
}

/*
.g-fade-container {
    &:deep(input) {
        text-transform: uppercase;
        white-space: nowrap;

        &:focus {
            background-color: var(--danger, $danger) !important;
        }
    }
}

.market-menu {
    border-radius: 9px;
    background-color: var(--env-bg, $env-bg);
    border: 1px solid var(--separator-color, $separator-color);

    & > label {
        padding: 8px 0 8px 12px;
        font-size: 1.2rem;
        cursor: pointer;
        text-transform: uppercase;
        gap: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--main-text-color, $main-text-color);
        fill: var(--main-text-color, $main-text-color);

        &:hover {
            color: var(--title-text-color, $title-text-color);
        }

        &.closed {
            pointer-events: none;
            color: var(--main-text-color, $main-text-color);

            & > .market {
                opacity: 0.6;
            }
        }

        &.active {
            font-weight: bold;
            pointer-events: none;
            color: var(--title-text-color, $title-text-color);
            background-color: var(--danger, $danger);
        }

        .market-name {
            flex-grow: 1;
        }

        & .timer,
        & .reason {
            white-space: nowrap;
            font-size: 1.1rem;
            padding-right: 10px;
            text-transform: capitalize;
            color: var(--title-text-color, $title-text-color);
        }

        & .timer::after {
            content: attr(data-countdown);
            width: 60px;
            margin-left: 4px;
            font-weight: 600;
            display: inline-block;
            font-family: $font-primary;
        }
    }
}
*/
</style>
