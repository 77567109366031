
import { watch } from 'vue';
import { widget } from '/public/charting_library';
import { STATE, i18n, WebSockets as Stream } from '@/Ship';
import { useChartActions } from '@chart';

const {
    onReady,
    getBars,
    resolveSymbol,
    subscribeBars,
    unsubscribeBars,
} = useChartActions();

export default class TradingView {

    static #instance = null;

    endpoint = 'klines';

    widget = null;

    config = {
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: true,
        supported_resolutions: STATE.MARKET === 'crypto'
            ? [ '1',  '5', '15', '30', '60', '240', '1D', '1W', '1M']
            : ['5', '15', '30', '60', '1D', '1W', '1M'],
    }

    options = {
        debug: false,
        symbol: STATE.SYMBOL,
        interval: 'D',
        container: 'tv_chart_container',
        library_path: '/charting_library/',
        locale: i18n.global.locale.value || 'en',
        disabled_features: ['use_localstorage_for_settings', 'popup_hints'],
        enabled_features: [],
        client_id: 'PFX',
        user_id: 'public_user_id',
        fullscreen: false,
        autosize: true,
        //theme: STATE.IS_LIGHT_MODE ? 'Light' : 'Dark',
        datafeedUrl: 'https://demo_feed.tradingview.com',
        charts_storage_api_version: '1.2',
        custom_css_url: '/tradingview.css',
        overrides: {
            'paneProperties.background': '#0d0d14',
            'paneProperties.vertGridProperties.color': '#262830',
            'paneProperties.horzGridProperties.color': '#262830',
            'scalesProperties.textColor' : '#ffffff',
        },

        datafeed: {
            onReady,
            resolveSymbol,
            getBars,
            subscribeBars,
            unsubscribeBars,
        },
    };

    constructor() {

        TradingView.#instance = this;

        this.stream = Stream.getInstance(process.env.VUE_APP_WEBSOCKET);

        watch([
            () => STATE.SYMBOL,
            () => STATE.IS_LIGHT_MODE,
            i18n.global.locale
        ], () => {
            this.options.symbol = STATE.SYMBOL;
            this.config.supported_resolutions = STATE.MARKET === 'crypto'
                ? [ '1',  '5', '15', '30', '60', '240', '1D', '1W', '1M']
                : ['5', '15', '30', '60', '1D', '1W', '1M'];
            //this.options.theme = STATE.IS_LIGHT_MODE ? 'Light' : 'Dark';
            this.options.locale = i18n.global.locale.value;
            this.restartWidget();
        });

        return TradingView.#instance;
    }

    static getInstance() {

        return (!TradingView.#instance)
            ? new TradingView()
            : TradingView.#instance;
    }

    restartWidget() {
        this.removeWidget();
        this.createWidget();
    }

    createWidget() {
        this.widget = new widget(this.options);

        this.widget.headerReady().then(() => {
            const button = this.widget.createButton();
            button.setAttribute('title', 'Open TradingView');
            button.addEventListener('click', function() {
                window.open('https://tradingview.com');
            });
            const logo = document.createElement('img');
            logo.setAttribute('src', require('@/Ship/assets/img/tradingview.svg'));
            logo.setAttribute('height', '40px');
            logo.setAttribute('style', 'cursor: pointer;');
            button.append(logo);
        });
    }

    removeWidget() {
        if (this.widget !== null) {
            this.widget.remove();
            this.widget = null;
        }
    }
}
