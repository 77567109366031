<template>
    <div class="wrapper">
        <div class="bg-overlay"></div>
        <topbar/>
        <environment/>
    </div>
</template>

<script>

import { useEnvComponents } from '@overlay';

const Topbar = useEnvComponents('Topbar');

export default {
    name: 'Layout',
    components: {
        Topbar,
    }
};
</script>

<style lang="scss">
.topbar {
    width: 100%;
    height: var(--topbar-height, $topbar-height);
}

.environment {
    overflow: auto;
    //height: calc(100vh - var(--bar-height, $bar-height));
    scrollbar-width: thin;
    scrollbar-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color) transparent;

    &::-webkit-scrollbar {
        width: 6px;
    }

    &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 1ex;
        background-color: var(--scrollbar-thumb-color, $scrollbar-thumb-color);
    }
}

.bg-overlay {
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: fixed;
}
</style>
